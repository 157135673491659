.root {}

.inputError {
  border: var(--borderErrorField);
}

.passwordContainer {
  display: flex;
  /* max-height: 50px; */
}

.passwordContainer>input {
  min-width: 100%;

}


input[type="password"]::-ms-reveal,
/* Edge */
input[type="password"]::-ms-clear,
/* Edge */
input[type="password"]::-webkit-clear-button,
/* Chrome */
input[type="password"]::-webkit-password-clear-button,
/* Chrome */
input[type="password"]::-webkit-textfield-decoration-container {
  display: none;
}