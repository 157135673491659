.inputContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.input {
    width: 50px;
    margin: 0;
    padding: 10px;
    -moz-appearance: textfield;
    font-size: 26px;
    text-align: center;
}

.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}