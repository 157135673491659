@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.defaultUserFields {}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.row,
.customFields {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  /* width: calc(34% - 9px); */
  composes: marketplaceModalPasswordMargins from global;
}

.lastNameRoot {
  /* width: calc(66% - 9px); */
  composes: marketplaceModalPasswordMargins from global;
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.smsCode {
  margin-top: 20px;
  margin-bottom: 40px;
}

.error {
  margin-top: 10px;
  margin-bottom: 10px;
  color: red;
}