.root {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10vw 0;
    margin: 0 auto;
    max-width: var(--contentMaxWidthPages);
}

.subtitle {
    font-size: 20px;
    font-weight: bold;
}

.subtitleSmall {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}


.info {
    padding: 0;
    margin: 0;
}

.list {
    padding-left: 60px;
    list-style: unset;
}

.listNumbers {
    padding-left: 60px;
    list-style: decimal;
}

.imageContainerHorizontal {
    margin-top: 20px;
    max-width: 60vw;
    justify-self: center;
    align-self: center;
    text-align: center;
}

.imageContainerVertical {
    margin-top: 20px;
    max-width: 40vw;
    justify-self: center;
    align-self: center;
    text-align: center;
}

.image {
    width: 100%;
}